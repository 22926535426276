// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://alajazamusic.com/alajazamusicadmin/', // new
  staticUrl: 'https://alajazamusic.com/alajazamusicadminassets/images/logo_pic.png',
  firebase: {
    apiKey: "AIzaSyASxLu_Dz3uq664t0g6zWcSExvU_enxmdw",
    authDomain: "alajazamobileapp-80452.firebaseapp.com",
    databaseURL: "https://alajazamobileapp-80452-default-rtdb.firebaseio.com",
    projectId: "alajazamobileapp-80452",
    storageBucket: "alajazamobileapp-80452.appspot.com",
    messagingSenderId: "909695087850",
    appId: "1:909695087850:web:85b63ef205d6ad946b5393",
    measurementId: "G-D81QK44PR8"
  }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
