import { HomeComponent } from './home/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page/landing-page.component';

const landingModule = () => import('./landing-page/landing-page.module').then(x => x.LandingPageModule);
const homeModule = () => import('./home/home.module').then(x => x.HomeModule);
const authModule = () => import('./authentication-user/authentication-user.module').then(x => x.AuthenticationUserModule);
const detailModule = () => import('./detail-page/detail-page.module').then(x => x.DetailPageModule);
const videoCategoryModule = () => import('./video-category/video-category.module').then(x => x.VideoCategoryModule);
const audioCategoryModule = () => import('./audio-category/audio-category.module').then(x => x.AudioCategoryModule);
const favAudioModule = () => import('./favorite-audio/favorite-audio.module').then(x => x.FavoriteAudioModule);
const favVideoModule = () => import('./favorite-video/favorite-video.module').then(x => x.FavoriteVideoModule);
const playlistModule = () => import('./playlist/playlist.module').then(x => x.PlaylistModule);
const radioModule = () => import('./radio/radio.module').then(x => x.RadioModule);
const liveVideoModule = () => import('./live-video/live-video.module').then(x => x.LiveVideoModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);
const passwordModule = () => import('./password/password.module').then(x => x.PasswordModule);
const searchModule = () => import('./search/search.module').then(x => x.SearchModule);
const footerModule = () => import('./footer-pages/footer-pages.module').then(x => x.FooterPagesModule);
const bookingModule = () => import('./booking/booking.module').then(x => x.BookingModule);
const contactusModule = () => import('./contactus/contactus.module').then(x => x.ContactusModule);
const merchandiseModule = () => import('./merchandise/merchandise.module').then(x => x.MerchandiseModule);
const paymenthistoryModule = () => import('./paymenthistory/paymenthistory.module').then(x => x.PaymenthistoryModule);

const routes: Routes = [
  { path: '', loadChildren: landingModule },
  { path: 'home', loadChildren: homeModule },
  { path: 'authentication', loadChildren: authModule },
  { path: 'detail-page', loadChildren: detailModule },
  { path: 'live-video', loadChildren: liveVideoModule },
  { path: 'video-category', loadChildren: videoCategoryModule },
  { path: 'audio-category', loadChildren: audioCategoryModule },
  { path: 'favorite-audio', loadChildren: favAudioModule },
  { path: 'favorite-video', loadChildren: favVideoModule },
  { path: 'playlist', loadChildren: playlistModule },
  { path: 'radio', loadChildren: radioModule },
  { path: 'profile', loadChildren: profileModule },
  { path: 'password', loadChildren: passwordModule },
  { path: 'search', loadChildren: searchModule },
  { path: 'footer', loadChildren: footerModule },
  { path: 'booking', loadChildren: bookingModule },
  { path: 'contactus', loadChildren: contactusModule },
  { path: 'merchandise', loadChildren: merchandiseModule },
  { path: 'paymenthistory', loadChildren: paymenthistoryModule },
  { path: '**', component: LandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
