<div *ngIf="sponserSliderData.length > 0 && showSponserSlider == true" class="sponcer-slider">
    <div class="container">
        <swiper class="swiper-container" [config]="bannerconfig">
            <div *ngFor="let item of sponserSliderData" class="swiper-slide">
                <a target="blank" [href]="item.link">
                    <div class="item">
                        <div class="zmovo-slider-contents">
                            <div class="ads-img">
                                <img [src]="item.image" alt="">
                            </div>

                        </div>
                    </div>
                </a>
            </div>
        </swiper>
    </div>
</div>

<!-- slider loader wrapper -->
<div class="loading-data banner-slider live-video-ads-banner " *ngIf="showSponserSlider == false">
    <div class="loading-card ">
        <div class="loading-img">
            <img src="../../../assets/image/ads-slider.png">
        </div>
        <div class="loading-content card br">
            <div class="container">
                <!-- <div class="wrapper">
                      <div class="profilePic animate"></div>
                      <div class="comment br animate w80"></div>
                      <div class="comment br animate"></div>
                      <div class="comment br animate"></div>
                  </div> -->
            </div>
        </div>
    </div>
</div>
<!-- slider loader wrapper -->

<footer class="zmovo-footer-area">
    <div class="zmovo-footer">
        <div class="container">
            <div class="zmovo-footer-center ">
                <div class="zmovo-ft-widgets ">
                    <div class="row ">

                        <div class="col-sm-6 col-lg-3">
                            <div class="zmovo-ft-widget">
                                <div class="zmovo-ft-widget-titles no-text">
                                    <h2 class="zmovo-ft-widget-title">Our Music </h2>
                                </div>
                                <div class="zmoto-ft-widget-contetn">
                                    <ul *ngIf="audioCategoryAllData.length > 0">
                                        <li *ngFor="let category of audioCategoryAllData">
                                            <a (click)="redirectToAudioCategory(category)">{{category.menu_name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="zmovo-ft-widget">
                                <div class="zmovo-ft-widget-titles no-text">
                                    <h2 class="zmovo-ft-widget-title">Our Videos</h2>
                                </div>
                                <div class="zmoto-ft-widget-contetn">
                                    <ul *ngIf="videoCategoryAllData.length > 0">
                                        <li *ngFor="let category of videoCategoryAllData">
                                            <a (click)="redirectToVideoCategory(category)">{{category.category_name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="zmovo-ft-widget">
                                <div class="zmovo-ft-widget-titles">
                                    <h2 class="zmovo-ft-widget-title">Explore </h2>
                                </div>
                                <div class="zmoto-ft-widget-contetn">
                                    <ul *ngIf="navigationMenu.length > 0">
                                        <li>
                                            <a [routerLink]="['home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Home</a>
                                        </li>
                                        <li *ngIf="showLiveVideoMenu == true">
                                            <a [routerLink]="['live-video', urlType]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Live Video</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['radio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Live Radio</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['video-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Videos</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['audio-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Music</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['favorite-audio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Favorites</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['playlist']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Playlists</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['booking']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Bookings</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['contactus']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Contact US</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="zmovo-ft-widget">
                                <div class="zmoto-ft-widget-contetn">
                                    <div class="zmovo-ft-social-widget">
                                        <div class="zmovo-ft-widget-titles">
                                            <h2 class="zmovo-ft-widget-title"> Connect With Us
                                            </h2>
                                        </div>
                                        <ul>
                                            <li><a [href]="shareLinkData[0]?.facebook_link" target="_blank"><span class="fa fa-facebook"></span></a></li>
                                            <li><a [href]="shareLinkData[0]?.twitter_link" target="_blank"><span class="fa fa-twitter"></span></a></li>
                                            <li><a [href]="shareLinkData[0]?.instagram_link" target="_blank"><span class="fa fa-instagram"></span></a></li>
                                            <li><a [href]="shareLinkData[0]?.other_link" target="_blank"><span class="fa fa-globe"></span></a></li>
                                        </ul>
                                    </div>


                                    <div class="foooter-app-link">
                                        <div class="zmovo-ft-widget-titles">
                                            <h2 class="zmovo-ft-widget-title"> Download Our App
                                            </h2>
                                        </div>
                                        <div class="footer-icon-wrapper">
                                            <a [href]="shareAppData[0]?.android_link" target="_blank"><img src="../../../assets/image/Google-play.png"></a>
                                            <a [href]="shareAppData[0]?.ios_link" target="_blank"><img src="../../../assets/image/App-store-Icons.png"></a>
                                            <a href="https://durisimoweb.com" target="_blank" class="custom-border"><img src="../../../assets/image/transparent_durisimoapp.png"></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="zmovo-footer-buttom" id="footer-space">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 col-lg-6">
                    <div class="zmovo-ft-menu">
                        <ul>
                            <li><a style="color: #0fb5de" (click)="goTOTermsAndConditions()">Terms of use</a></li>
                            <li><a style="color: #0fb5de" (click)="goTOPrivacyPolicy()">Privacy Policy</a></li>
                            <!-- <li><a href="">About</a></li>
              <li><a href="">Our Ads</a></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-sm-7 col-lg-6">
                    <div class="zmovo-ft-copyright">
                        <p>All Rights Reserved {{year}} | Powered By : <a target="_blank" href="http://www.alajazamusic.com">Ala Jaza Music</a></p>
                        <!-- <p>CopyRight 2021 Develop by <a href="" class="c1">Themepul</a> . All Rights Reserved.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="to-top" id="back-top">
    <i class="fa fa-angle-up"></i>
</div>